


















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Dashboard extends Vue {
  private entries = [
    // { path: '/photo-management/detail?metaTitle=新增图片', name: '新增图片' }
    // { path: '/apps-management/detail?metaTitle=新增标本', name: '新增标本' }
  ]
}
